<div class="full-screen">

  <div class="background"></div>
  <div class="container">
    <div class="row" *ngIf="!loading">
      <div class="modalbox success col-sm-8 col-md-6 col-lg-5 center animate" *ngIf="success; else errorTemplate">
        <div class="icon">
          <span class="material-icons">done</span>
        </div>
        <!--/.icon-->
        <h1>Éxito!</h1>
        <p>
          Confirmación de registro realizada con éxito.
        </p>
        <button mat-flat-button color="primary" type="button"
                class="p-button-primary mb-3" routerLink="login">
          Iniciar sesión
        </button>
      </div>
      <!--/.success-->
    </div>
  </div>
</div>


<ng-template #errorTemplate>
  <div class="modalbox error col-sm-8 col-md-6 col-lg-5 center animate">
    <div class="icon">
      <span class="material-icons">thumb_down</span>
    </div>
    <h1>Oh no!</h1>
    <p>{{mensajeError}}
    </p>
    <button mat-flat-button color="primary" type="button"
            class="p-button-primary mb-3" routerLink="/">
      Inicio
    </button>
    <!--    <button type="button" class="redo btn">Try again</button>-->
  </div>
</ng-template>
