import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes'
})
export class IncludesPipe implements PipeTransform {
    transform(items: any[] | string, value): any {
        return items.includes(value);
    }
}
