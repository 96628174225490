import {Injectable} from '@angular/core';
import {HttpService} from "../http.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpHeaders, HttpParameterCodec, HttpParams} from "@angular/common/http";
import {AuthService} from "../auth.service";

export class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    private http: HttpService,
    private authService: AuthService
  ) {
  }

  login(body: any): Observable<any> {
    let request = new HttpParams({
      fromObject: body,
      encoder: new CustomHttpParamEncoder()
    });
    return this.http.post(`oauth/token`, request, {
      headers: new HttpHeaders().append('Authorization', 'Basic Y2xpZW50YXBwOjEyMzQ1Ng=='),
      disableHandler: true,
      ignoreRefreshToken: true
    });
  }

  recoverPassword(email: string): Observable<any> {
    return this.http.get(`accesoController/validarCuentaRecuperacion/${email}/`, {
      headers: new HttpHeaders().append('Authorization', 'Basic Y2xpZW50YXBwOjEyMzQ1Ng=='),
      disableHandler: true,
      ignoreRefreshToken: true
    }).pipe(
      map(data => data.respuesta)
    );
  }

  changePassword(body: any): Observable<any> {
    return this.http.post(`accesoController/recuperarCuentaParticipante`, body, {
      // headers: new HttpHeaders().append('Authorization', 'Basic Y2xpZW50YXBwOjEyMzQ1Ng=='),
      disableHandler: true,
      ignoreRefreshToken: true
    }).pipe(
      map(data => data.respuesta)
    );
  }

  validateUserAccount(body: any) {
    return this.http.post(`accesoController/validarCuentaAlumno`, body, {
      ignoreRefreshToken: true
    }).pipe(
      map((response: any) => response.respuesta)
    );
  }

  validateTeachingAccount(body: any) {
    return this.http.post(`accesoController/validarCuentaDocente`, body, {
      ignoreRefreshToken: true
    }).pipe(
      map((response: any) => response.respuesta)
    );
  }

  validateExternalAccount(body: any) {
    return this.http.post(`accesoController/validarCuentaExterno`, body, {
      ignoreRefreshToken: true
    }).pipe(
      map((response: any) => response.respuesta)
    );
  }

  validateInvited(body: any) {
    return this.http.post(`accesoController/validarCuentaInvitado`, body, {
      ignoreRefreshToken: true
    }).pipe(
      map((response: any) => response.respuesta)
    );
  }

  signUp(body: any) {
    return this.http.post(`accesoController/registrarParticipante`, body, {
      ignoreRefreshToken: true
    }).pipe(
      map((response: any) => response.respuesta)
    );
  }

  confirmRegistration(token: string) {
    return this.http.get(`accesoController/confirmarRegistro/${token}`, {
      ignoreRefreshToken: true
    });
  }

  constanciaValidarToken(token: string) {
    return this.http.get(`api/consultaConstanciaController/validarToken/${token}`, {
      ignoreRefreshToken: true
    });
  }

  consultarConstanciaCongresoPorToken(token: string) {
    return this.http.get(`api/consultaConstanciaController/consultarConstanciaCongresoPorToken/${token}`, {
      ignoreRefreshToken: true
    }).pipe(
      map(data => data.respuesta)
    );
  }

// ---------- NEED TOKEN SERVICES ---------
  getParticipantDetail(): Observable<any> {
    return this.http.get(`api/participanteController/obtenerDetallePorParticipante/`).pipe(
      map(data => {
        const userType = ['alumno', 'docente', 'externo', 'invitado'].find(type => data.respuesta[type]);
        data.respuesta.userType = userType;
        if (data.respuesta[userType]) {
          data.respuesta.fullName = [
            data.respuesta[userType].nombres,
            data.respuesta[userType].apaterno,
            data.respuesta[userType].amaterno
          ].filter(v => v).join(' ');
          data.respuesta.curp = data.respuesta[userType].curp;
          if (userType === 'docente') {
            data.respuesta.rfc = data.respuesta[userType].rfc;
          }
        }
        return data.respuesta;
      })
    );
  }

  getConferencesByUser(disableMessageError: boolean = false): Observable<any> {
    return this.http.get(`api/participanteController/obtenerPonenciasPorParticipanteCongresoActivo`, {
      headers: {
        meta: JSON.stringify({
          disableMessageError: disableMessageError
        })
      }
    }).pipe(
      map(data => data.respuesta)
    );
  }

  obtenerPonenciasParaConstanciasPorParticipanteCongresoActivo(): Observable<any> {
    return this.http.get(`api/participanteController/obtenerPonenciasParaConstanciasPorParticipanteCongresoActivo`).pipe(
      map(data => data.respuesta)
    );
  }

  generarConstanciaCongreso(): Observable<any> {
    return this.http.get(`api/reportesController/generarConstanciaCongreso/`).pipe(
      map(data => data.respuesta)
    );
  }

  getConfereces(): Observable<any> {
    return this.http.get(`api/ponenciasController/obtenerPonenciasPorCongresoActivo`).pipe(
      map(data => data.respuesta)
    )
  }

  getConferenceById(id): Observable<any> {
    return this.http.get(`api/ponenciasController/obtenerDetallePonencia/${id}/`).pipe(
      map(data => data.respuesta)
    )
  }

  preRegister(idConference): Observable<any> {
    return this.http.put(`api/ponenciasController/registrarParticipanteEnPonencia/${idConference}/`, {}).pipe(
      map(data => data.respuesta)
    )
  }

  cancelRegistration(idConference): Observable<any> {
    return this.http.delete(`api/ponenciasController/eliminarParticipanteEnPonencia/${idConference}/`).pipe(
      map(data => data.respuesta)
    )
  }

  getStates() {
    return this.http.post(`api/catalogosController/obtenerMultiplesCatalogos`, {
      catalogos: [{
        id: 1,
        cveCatalogo: 'EDO',
        cveFiltro: ''
      }]
    }, {
      ignoreRefreshToken: true,
      ignoreInterceptor: true
    }).pipe(
      map(response => response.respuesta[0].respuesta)
    );
  }

  updateParticipant(body: any) {
    return this.http.post(`api/participanteController/actualizarDatosParticipante`, body);
  }

  registerAttendance(idConference) {
    return this.http.put(`api/participanteController/registrarAsistenciaEnPonencia/${idConference}/`, {});
  }

  obtenerIndicadoresEvaluacionPorEvento() {
    return this.http.get(`api/evaluacionesController/obtenerIndicadoresEvaluacionPorEvento`).pipe(
      map(data => data.respuesta)
    )
  }

  obtenerPreguntasEvaluacionPorTipoPonencia(idTipoPonencia) {
    return this.http.get(`api/evaluacionesController/obtenerPreguntasEvaluacionPorTipoPonencia/${idTipoPonencia}/`).pipe(
      map(data => data.respuesta)
    )
  }

  registrarEvaluacionParticipantePorPonencia(data): Observable<any> {
    return this.http.put(`api/evaluacionesController/registrarEvaluacionParticipantePorPonencia`, data).pipe(
      map(data => data.respuesta)
    )
  }

  obtenerPreguntasEvaluacionPorEvento() {
    return this.http.get(`api/evaluacionesController/obtenerPreguntasEvaluacionPorEvento`).pipe(
      map(data => data.respuesta)
    )
  }

  registrarEvaluacionParticipantePorEvento(data): Observable<any> {
    return this.http.put(`api/evaluacionesController/registrarEvaluacionParticipantePorEvento`, data).pipe(
      map(data => data.respuesta)
    )
  }

  generarConstanciaTaller(idTaller): Observable<any> {
    return this.http.get(`api/reportesController/generarConstanciaTaller/${idTaller}`).pipe(
      map(data => data.respuesta)
    );
  }

  consultarConstanciaTallerPorToken(token): Observable<any> {
    return this.http.get(`api/consultaConstanciaController/consultarConstanciaTallerPorToken/${token}`, {
      ignoreRefreshToken: true
    }).pipe(
      map(data => data.respuesta)
    );
  }

  obtenerNotificacionSistema() {
    return this.http.get(`notificacionSistemaController/obtenerNotificacionSistema`, {
      ignoreRefreshToken: true,
      ignoreInterceptor: true
    });
  }
}
