import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform {
    transform(date, formatInitial, format): any {
        let res;
        if (date) {
            switch (formatInitial) {
                case 'date':
                    res = moment(date).format(format);
                    break;
                default:
                    res = moment(date, formatInitial).format(format);
                    break;
            }
        } else {
            res = date;
        }
        return res === 'Invalid date' ? date : res;
    }
}
