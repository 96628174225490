import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getErrorMessage'
})
export class GetErrorMessagePipe implements PipeTransform {
  messages = {
    required: 'Este campo es requerido',
    confirmedValidator: 'Las contraseñas deben ser iguales',
    email: 'Es requerida una cuenta de correo electrónico valida',
    maxlength: 'El número máximo de caracteres es ',
    minlength: 'El número mínimo de caracteres es '
  };

  transform(value: any, messages: any = null): unknown {
    if(messages) {
      this.messages = {
        ...this.messages,
        ...messages
      }
    }
    if (value) {
      const keys = Object.keys(value);
      const message = this.messages[keys[0]] || keys[0];
      switch (keys[0]) {
        case 'maxlength':
          return this.messages.maxlength + value.maxlength.requiredLength;
        case 'minlength':
          return this.messages.minlength + value.minlength.requiredLength;
      }
      return message;
    }
    return '';
  }
}
