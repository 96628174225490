import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router
  ) {
  }

  private passwordTempSubject: BehaviorSubject<string> = new BehaviorSubject('');
  private passwordTemp: string = '';

  setPassTemp(password) {
    this.passwordTemp = password;
    this.passwordTempSubject.next(this.passwordTemp);
  }

  getPassTemp(): Observable<any> {
    return this.passwordTempSubject.asObservable();
  }

  isLoggedIn(): boolean {
    return Boolean(this.getToken());
  }

  getUser(): any {
    let user;
    if (localStorage.getItem('current-user')) {
      user = JSON.parse(localStorage.getItem('current-user'));
    }
    return user;
  }

  getRefreshToken() {
    return (this.getUser() || {}).refresh_token;
  }

  isTokenExpired() {
    return this.getUser().token_expires_at - (new Date().getTime() + 30000) < 0;
  }

  setUser(user: any): void {
    user.token_expires_at = new Date().getTime() + user.expires_in * 1000;
    localStorage.setItem('current-user', JSON.stringify(user));
  }

  deleteUser(): void {
    localStorage.removeItem('current-user');
    this.router.navigate(['/login']);
  }

  getToken(): string {
    const user = this.getUser();
    if (user) {
      return user.access_token;
    }
    return '';
  }
}
