import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../core/services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {MainService} from "../../../core/services/http/main.service";

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit {
  loading: boolean = true;
  success: boolean = true;
  mensajeError: string = '';

  constructor(
    private mainService: MainService,
    private ar: ActivatedRoute
  ) {

    this.mainService.confirmRegistration(ar.snapshot.params.token).subscribe(data => {
      this.success = true;
      this.loading = false;
    }, error => {
      if (error.codig === -1) {
        this.mensajeError = error.mensaje;
      } else {
        this.mensajeError = 'Ocurrió un error, intente mas tarde';
      }
      this.loading = false;
      this.success = false;
    });
  }

  ngOnInit(): void {
  }

}
