import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpService} from './services/http.service';
import {AuthService} from './services/auth.service';
import {LoaderService} from './services/loader.service';
import {AlertService} from './services/alert.service';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {FilterPipe} from './pipes/filter.pipe';
import {MomentPipe} from './pipes/moment.pipe';
import {RouterModule} from '@angular/router';
import {IncludesPipe} from './pipes/includes.pipe';

import {RegExpTestPipe} from './pipes/regExpTest.pipe';
import {HistoryBackDirective} from './directives/history-back.directive';
import {MaskDirective} from './directives/mask.ditective';
import {ModuleWithProviders} from '@angular/compiler/src/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './guards/auth/auth.guard';
import {NoAuthGuard} from './guards/no-auth/no-auth.guard';
import {HasAccessGuard} from './guards/has-access/has-access.guard';

//--------------- MATERIAL COMPONENTS ---------------
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {FlexLayoutModule} from "@angular/flex-layout";
import {SidebarService} from "./services/sidebar.service";
import {MatCardModule} from "@angular/material/card";
import {CoreHttpInterceptor} from "./http.interceptor";
import { GetErrorMessagePipe } from './pipes/get-error-message.pipe';
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";


@NgModule({
  declarations: [
    FilterPipe,
    MomentPipe,
    IncludesPipe,
    RegExpTestPipe,
    HistoryBackDirective,
    MaskDirective,
    GetErrorMessagePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    FlexLayoutModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FilterPipe,
    MomentPipe,
    IncludesPipe,
    RegExpTestPipe,
    RouterModule,
    HistoryBackDirective,
    MaskDirective,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    FlexLayoutModule,
    GetErrorMessagePipe,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    FilterPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        HttpService,
        AuthService,
        LoaderService,
        AlertService,
        AuthGuard,
        NoAuthGuard,
        HasAccessGuard,
        SidebarService
      ]
    };
  }
}

