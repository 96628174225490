import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend, HttpParams, HttpHeaders} from '@angular/common/http';
import {environment as params} from './../../../environments/environment';
import {map, finalize, tap, switchMapTo, catchError} from 'rxjs/operators';
import {Observable, Subject, throwError} from 'rxjs';
import {LoaderService} from './loader.service';
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private pendingRequests = 0;
  public api: string;
  private readonly httpNoInterceptor: HttpClient;
  private waitRefreshToken = false;
  private refreshTokenSub = new Subject<any>();

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private loaderService: LoaderService,
    private auth: AuthService,
    private router: Router
  ) {
    this.api = params.api;
    this.httpNoInterceptor = new HttpClient(this.handler);
  }

  codeHandler(data: any) {
    if (data.codigo !== 0) {
      throw data;
    }
    return data;
  }

  checkToken(request, opts: any): Observable<any> {
    if (opts && opts.ignoreRefreshToken || !this.auth.isTokenExpired()) {
      return request;
    }
    if (this.waitRefreshToken) {
      return this.refreshTokenSub.asObservable().pipe(
        switchMapTo(request)
      );
    }
    this.waitRefreshToken = true;
    return this.refreshToken().pipe(
      switchMapTo(request)
    );
  }

  getHttp(opts: any): HttpClient {
    if (!opts.disableLoader) {
      this.turnOnRequest();
    }
    return opts.ignoreInterceptor ? this.httpNoInterceptor : this.http;
  }

  // getHttpOpts(opts: any): any {
  //   if (!opts) return {};
  //   let headers = new Headers();
  //   let nvoOpts: any = {};
  //   if (opts.token) {
  //     headers.set('Token', JSON.parse(localStorage.getItem('current-user')).token);
  //     nvoOpts.headers = headers;
  //   }
  //   console.log(nvoOpts);
  //   return nvoOpts;
  // }

  get(urlService: string, opts: any = {}): Observable<any> {
    let request = this.checkToken(
      this.getHttp(opts).get(`${opts.baseUrl ? opts.baseUrl : this.api}${urlService}`, opts),
      opts
    );
    request = request.pipe(
      map(data => {
        return !opts.disableHandler ? this.codeHandler(data) : data;
      }),
      finalize(() => {
        if (!opts.disableLoader) {
          this.turnOffRequest();
        }
      })
    );
    return request;
  }

  post(urlService: string, data: any, opts: any = {}): Observable<any> {
    let request = this.checkToken(
      this.getHttp(opts).post(`${opts.baseUrl ? opts.baseUrl : this.api}${urlService}`, data, opts)
      , opts
    );
    request = request.pipe(
      map(data => {
          return !opts.disableHandler ? this.codeHandler(data) : data;
        }
      ),
      finalize(() => {
        if (!opts.disableLoader) {
          this.turnOffRequest();
        }
      })
    );
    return request;
  }

  put(urlService: string, data: any, opts: any = {}): Observable<any> {
    let request = this.checkToken(
      this.getHttp(opts).put(`${opts.baseUrl ? opts.baseUrl : this.api}${urlService}`, data, opts),
      opts
    );
    request = request.pipe(
      map(data => {
        return !opts.disableHandler ? this.codeHandler(data) : data;
      }),
      finalize(() => {
        if (!opts.disableLoader) {
          this.turnOffRequest();
        }
      })
    );
    return request;
  }

  delete(urlService: string, opts: any = {}): Observable<any> {
    let request = this.checkToken(
      this.getHttp(opts).delete(`${opts.baseUrl ? opts.baseUrl : this.api}${urlService}`),
      opts
    );
    request = request.pipe(
      map(data => {
        return !opts.disableHandler ? this.codeHandler(data) : data;
      }),
      finalize(() => {
        if (!opts.disableLoader) {
          this.turnOffRequest();
        }
      })
    );
    return request;
  }

  turnOnRequest() {
    this.pendingRequests++;
    this.loaderService.showLoader();
  }

  turnOffRequest() {
    this.pendingRequests--;
    if (this.pendingRequests <= 0) {
      this.loaderService.hideLoader();
    }
  }

  refreshToken(): Observable<any> {
    let request = new HttpParams({
      fromObject: {
        grant_type: 'refresh_token',
        refresh_token: this.auth.getRefreshToken()
      }
    });
    return this.post(`oauth/token`, request, {
      headers: new HttpHeaders().append('Authorization', 'Basic Y2xpZW50YXBwOjEyMzQ1Ng=='),
      disableHandler: true,
      ignoreInterceptor: true,
      ignoreRefreshToken: true
    }).pipe(
      tap(data => {
        this.auth.setUser(data);
        this.waitRefreshToken = false;
        this.refreshTokenSub.next(data);
        this.turnOffRequest();
      }),
      catchError((error) => {
        this.turnOffRequest();
        this.auth.deleteUser();
        this.router.navigate(['/login']).then(() => {
        });
        return throwError(error);
      })
    );
  }
}
