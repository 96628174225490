import {Component} from '@angular/core';
import * as moment from 'moment';
import {MainService} from "./core/services/http/main.service";
import {AuthService} from "./core/services/auth.service";
import {Router} from "@angular/router";
import {AlertService} from "./core/services/alert.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private router: Router,
    private alert: AlertService
  ) {
    moment.locale('es');
    this.revisarMantenimientoService();
    setInterval(() => {
      this.revisarMantenimientoService();
    }, 300000);
  }


  revisarMantenimientoService() {
    this.mainService.obtenerNotificacionSistema().subscribe(data => {
    }, error => {
      if (error && error.codigo === -2) {
        if (this.authService.isLoggedIn()) {
          this.authService.deleteUser();
          this.router.navigate(['']).then(() => {
          });
        }
        this.alert.alert({
          text: error.mensaje,
          showCancelButton: false,
          showConfirmButton: false
        }).then(() => {
        });
      }
    });
  }
}
