import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoaderComponent implements OnInit {
  show: Observable<boolean>;
  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.show = this.loaderService.getShowLoader();
  }
}
