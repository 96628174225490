import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderModule} from './core/components/loader/loader.module';
import {ConfirmRegistrationComponent} from './views/pages/confirm-registration/confirm-registration.component';
import { ConstanciaIngresoComponent } from './views/pages/constancia-ingreso/constancia-ingreso.component';
import {MatToolbarModule} from "@angular/material/toolbar";

@NgModule({
  declarations: [
    AppComponent,
    ConfirmRegistrationComponent,
    ConstanciaIngresoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    LoaderModule,
    MatToolbarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
