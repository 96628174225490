import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './core/guards/auth/auth.guard';
import {NoAuthGuard} from './core/guards/no-auth/no-auth.guard';
import {ConfirmRegistrationComponent} from "./views/pages/confirm-registration/confirm-registration.component";
import {ConstanciaIngresoComponent} from "./views/pages/constancia-ingreso/constancia-ingreso.component";

const routes: Routes = [
  {
    path: 'confirm-registration/:token',
    component: ConfirmRegistrationComponent
  }, {
    path: 'constancia/:token',
    component: ConstanciaIngresoComponent
  }, {
    path: 'constanciaTaller/:token',
    component: ConstanciaIngresoComponent,
    data: {
      tallerModo: true
    }
  }, {
    path: '',
    loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
    canActivateChild: [NoAuthGuard]
  }, {
    path: '',
    loadChildren: () => import('./views/main/main.module').then(m => m.MainModule),
    canLoad: [AuthGuard]
  }, {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
