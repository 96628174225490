<div class="full-screen" *ngIf="!constancia">

  <div class="background"></div>
  <div class="container">
    <form [formGroup]="form" (submit)="consultarConstancia()" class="row">
      <div class="modalbox success col-sm-8 col-md-6 col-lg-5 center animate px-2 px-md-4" *ngIf="success; else errorTemplate">
        <!--<div class="icon">
          <span class="material-icons">done</span>
        </div>
        &lt;!&ndash;/.icon&ndash;&gt;
        <h1>Éxito!</h1>
        <p>
          Confirmación de registro realizada con éxito.
        </p>-->
        <div class="row">
          <re-captcha
            #captchaRef="reCaptcha"
            siteKey="6LfyFb4aAAAAAElmZk_KhkUnyelkWH5-zgiIcvF9"
            formControlName="recaptcha"
          ></re-captcha>
        </div>
        <mat-error class="captcha-error-msg" *ngIf="submitted && form.get('recaptcha').invalid">Captcha debe ser
          resuelto
        </mat-error>
        <button mat-flat-button color="primary" type="submit" class="p-button-primary mb-3">
          Consultar constancia
        </button>
      </div>
      <!--/.success-->
    </form>
  </div>
</div>


<ng-template #errorTemplate>
  <div class="modalbox error col-sm-8 col-md-6 col-lg-5 center animate">
    <div class="icon">
      <span class="material-icons">thumb_down</span>
    </div>
    <h1>Oh no!</h1>
    <p>{{mensajeError}}
    </p>

    <button mat-flat-button color="primary" type="button"
            class="p-button-primary mb-3" routerLink="/">
      Inicio
    </button>
    <!--    <button type="button" class="redo btn">Try again</button>-->
  </div>
</ng-template>

<div class="container-fluid p-0" *ngIf="constancia">
  <mat-toolbar color="primary">
    <div class="container px-0 px-md-3 text-wrap" style="line-height: 27px;">
      Sistema de Autenticación de Constancias
    </div>
  </mat-toolbar>
  <div class="container pb-5">
    <h2 class="mt-4">Datos del Taller</h2>
    <ul class="list-group">
      <li class="list-group-item d-flex flex-column flex-md-row mx-0">
        <b class="col-12 col-md-5">Nombre: </b>
        <span class="col-12 col-md-7">
          {{constancia.evento.descripcion}}
        </span>
      </li>
      <!--<li class="list-group-item d-flex flex-column flex-md-row mx-0">
        <b class="col-12 col-md-5">Descripción: </b>
        <span class="col-12 col-md-7">
          {{constancia.evento.observaciones}}
        </span>
      </li>
      <li class="list-group-item d-flex flex-column flex-md-row mx-0">
        <b class="col-12 col-md-5">Fechas: </b>
        <span class="col-12 col-md-7">
          <ng-container *ngFor="let fecha of constancia.evento.fechas">
            {{fecha.descripcion}}
            <br>
          </ng-container>
        </span>
      </li>-->
    </ul>

    <ng-container *ngIf="tallerModo">
      <h2 class="mt-4">Datos del Taller</h2>
      <ul class="list-group">
        <li class="list-group-item d-flex flex-column flex-md-row mx-0">
          <b class="col-12 col-md-5">Nombre: </b>
          <span class="col-12 col-md-7">
          {{constancia.ponencia.descripcion}}
        </span>
        </li>
        <li class="list-group-item d-flex flex-column flex-md-row mx-0">
          <b class="col-12 col-md-5">Descripción: </b>
          <span class="col-12 col-md-7">
          {{constancia.ponencia.observaciones}}
        </span>
        </li>
        <li class="list-group-item d-flex flex-column flex-md-row mx-0">
          <b class="col-12 col-md-5">Fecha: </b>
          <span class="col-12 col-md-7">
            {{constancia.ponencia.fecha}}
          </span>
        </li>
      </ul>
    </ng-container>

    <h2 class="mt-4">Datos del Participante</h2>

    <ul class="list-group">
      <li class="list-group-item d-flex flex-column flex-md-row mx-0">
        <b class="col-12 col-md-5">Nombre(s): </b>
        <span class="col-12 col-md-7">
          {{constancia.participante.nombres}}
        </span>
      </li>
      <li class="list-group-item d-flex flex-column flex-md-row mx-0">
        <b class="col-12 col-md-5">Apellido Paterno: </b>
        <span class="col-12 col-md-7">
          {{constancia.participante.apaterno}}
        </span>
      </li>
      <li class="list-group-item d-flex flex-column flex-md-row mx-0">
        <b class="col-12 col-md-5"> Apellido Materno: </b>
        <span class="col-12 col-md-7">
          {{constancia.participante.amaterno}}
        </span>
      </li>
    </ul>
    <h2 class="mt-4">Fechas</h2>

    <ul class="list-group">
      <li class="list-group-item d-flex flex-column flex-md-row mx-0" >
        <b class="col-12 col-md-5">Fecha(s): </b>
        <span class="col-12 col-md-7">
          {{constancia.evento.fechasString}}
        </span>
      </li>
      <li class="list-group-item d-flex flex-column flex-md-row mx-0" >
        <b class="col-12 col-md-5">Duración: </b>
        <span class="col-12 col-md-7">
          {{constancia.evento.duracion}}
        </span>
      </li>
    </ul>
  </div>
</div>