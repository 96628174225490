import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  confirmButtonColor = '#001C34';
  confirmButtonText = 'Aceptar';
  cancelButtonText = 'Cancelar';
  width = '490px';
  customClass = {
    container: 'swal-custom',
    popup: 'swal-custom-popup',
    header: 'swal-custom-header',
    title: 'swal-custom-title',
    closeButton: 'swal-custom-close-button',
    icon: 'swal-custom-icon',
    image: 'swal-custom-image',
    content: 'swal-custom-content',
    input: 'swal-custom-input',
    actions: 'swal-custom-actions',
    confirmButton: 'swal-custom-confirm-button',
    cancelButton: 'swal-custom-cancel-button',
    footer: 'swal-custom-footer'
  };

  constructor() { }

  alert(options: any): Promise<any> {
    const swalOpts = {
      title: '',
      width: this.width,
      confirmButtonColor: this.confirmButtonColor,
      confirmButtonText: this.confirmButtonText,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: this.customClass,
      ...options
    };
    return this.fire(swalOpts);
  }

  confirm(options: any): Promise<any> {
    const swalOpts = {
      title: '',
      width: this.width,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonColor: this.confirmButtonColor,
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText,
      reverseButtons: true,
      customClass: this.customClass,
      ...options
    };
    return this.fire(swalOpts);
  }

  warn(swalOpts: any = {}) {
    swalOpts.html = `
      <div class="alert alert-warning container-fluid">
        <div class="col-xs-2 align-middle">
          <i class="material-icons big">&#xE002;</i>
        </div>
        <div class="col-xs-10 align-middle">
          <h4>IMPORTANTE</h4>
          <p>Proceso no reversible</p>
        </div>
      </div>
      <p class="m-0">${swalOpts.text}</p>
    `;
    return this.confirm(swalOpts);
  }


  fire(swalOpts): Promise<any> {
    return new Promise((resolve, reject) => {
      swal.fire(swalOpts).then((data) => {
        if (!data.dismiss) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }
}
