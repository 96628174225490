import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[appHistoryBack]'
})
export class HistoryBackDirective {

  constructor(
    private _location: Location,

  ) { }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.back();
  }

  back() {
    this._location.back();
  }

}
