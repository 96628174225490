import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'regExpTest'
})
export class RegExpTestPipe implements PipeTransform {
    transform(str: string, regexp): any {
        return new RegExp(regexp).test(str);
    }
}
