import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarStateSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private sidebarState: boolean = true;

  private pageNameSubject: BehaviorSubject<string> = new BehaviorSubject('');
  private pageName: string = '';

  constructor() {
  }

  setPageName(name: string) {
    this.pageName = name;
    this.pageNameSubject.next(this.pageName);
  }

  getPageName(): Observable<string> {
    return this.pageNameSubject.asObservable();
  }

  setSidebarState(state: boolean) {
    this.sidebarState = state;
    this.sidebarStateSubject.next(this.sidebarState);
  }

  getSidebarState(): Observable<boolean> {
    return this.sidebarStateSubject.asObservable();
  }

  toggleSidebar() {
    this.setSidebarState(!this.sidebarState);
  }


}
