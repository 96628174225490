import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MainService} from "../../../core/services/http/main.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RecaptchaComponent} from "ng-recaptcha";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-constancia-ingreso',
  templateUrl: './constancia-ingreso.component.html',
  styleUrls: ['./constancia-ingreso.component.scss']
})
export class ConstanciaIngresoComponent implements OnInit, OnDestroy {
  @ViewChild('captchaRef', {static: false}) captcha: RecaptchaComponent;
  success: boolean = true;
  mensajeError: string = '';
  form: FormGroup;
  submitted: boolean = false;
  constancia: any;
  tallerModo = false;
  arDataSub: Subscription;

  constructor(
    private mainService: MainService,
    private ar: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.arDataSub = this.ar.data.subscribe(data => {
      this.tallerModo = data.tallerModo;
    });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      recaptcha: [null, [Validators.required]]
    });
  }

  consultarConstancia() {
    this.submitted = true;
    if (this.form.valid) {
      this.mainService.constanciaValidarToken(this.ar.snapshot.params.token).subscribe(data => {
        this.success = true;
        (this.tallerModo
            ? this.mainService.consultarConstanciaTallerPorToken(this.ar.snapshot.params.token)
            : this.mainService.consultarConstanciaCongresoPorToken(this.ar.snapshot.params.token)
        ).subscribe(data => {
          this.constancia = data;
        }, this.errorHandler.bind(this));
      }, this.errorHandler.bind(this));
    }
  }

  errorHandler(error) {
    if (error.codig === -1) {
      this.mensajeError = error.mensaje;
    } else {
      this.mensajeError = 'Ocurrió un error, intente mas tarde';
    }
    this.success = false;
    this.captcha.reset();
  }

  ngOnDestroy() {
    if (this.arDataSub) {
      this.arDataSub.unsubscribe();
    }
  }

}
