import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {finalize, catchError, map} from 'rxjs/operators';

import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from './services/auth.service';
import {AlertService} from './services/alert.service';

@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private alertService: AlertService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    const metaHeader = JSON.parse(req.headers.get('meta') || '{}');
    if (token) {
      req = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${token}`)
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json')
          .delete('meta')
      });
    }
    return next
      .handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.codigo !== undefined && event.body.codigo !== 0) {
              throw event.body;
            }
            return event;
          }
        }),
        catchError((error) => {
          if (error.status === 0 && error.error instanceof ProgressEvent) {
            this.alertService.alert({
              title: 'Error',
              text: 'Servicio no disponible, intente más tarde'
            }).then(() => {
            });
          } else if (error.status === 401) {

            if(error.error.error === 'invalid_token'){
              error.error.error_description = null;
            }

            this.alertService.alert({
              title: 'Error',
              text:  ( error.error.error_description) || "Su sesión ha expirado, favor de ingresar nuevamente"
            }).then(() => {
              this.auth.deleteUser();
            });
          } else {
            if (!metaHeader.disableMessageError) {
              switch (Number(error.codigo)) {
                case -1:
                  this.alertService.alert({
                    title: 'Error',
                    text: 'Ocurrió un error, intente mas tarde'
                  }).then(() => {
                  });
                  break;
                case -2:
                  this.alertService.alert({
                    title: 'Error',
                    text: error.mensaje || error.respuesta && error.respuesta.mensaje
                  }).then(() => {
                  });
                  break;
              }
            }
          }
          return throwError(error);
        }),
        finalize(() => {
        })
      );
  }
}
